@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}

@layer components {
  .container_lg {
    @apply mx-auto max-w-full px-6 md:px-10 xl:px-5 2xl:max-w-[1320px];
  }
  .titleClass {
    @apply text-[#1C1E29] text-3xl font-semibold font-openSans tracking-tight;
  }
  .paraClass {
    @apply text-[#1C1E29] text-base font-openSans font-medium;
  }
  .btnClass {
    @apply uppercase font-semibold font-openSans text-soft-white bg-primary hover:bg-primary-hover duration-200 px-7 py-2.5;
  }
}
@layer utilities {
  .sectionPy {
    padding: 80px 0;
  }
}

.swiper-button-disabled {
  @apply opacity-40 cursor-not-allowed;
}

img {
  width: 100%;
  object-fit: contain;
  height: auto;
}

#news_pagination .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
  margin: 0 !important;
}
#news_pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #eaab1c !important;
}
